import {
  CheckCircleOutlined,
  DownloadOutlined,
  FileTextOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Row, Tooltip, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DocumentUpload({
  title,
  uploaded,
  downloadDocumentOnClick,
  onClick,
  beforeUpload,
  loading,
  downloadDocument,
  customButton,
}) {
  const { t } = useTranslation();
  return (
    <Col span={24}>
      <Row style={{ alignItems: 'center' }}>
        <div className="icon-color">
          <FileTextOutlined style={{ marginRight: 5 }} />
          {title}
        </div>
        {uploaded && (
          <Tooltip title={t('orders.documents.document_uploaded')}>
            <CheckCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        )}
        {downloadDocument && ( // TODO: Implementar en back para otro tipo de envíos
          <>
            <Divider type="vertical" />
            <Row>
              {customButton || (
                <Button
                  type="link"
                  onClick={downloadDocumentOnClick}
                  style={{
                    color: '#1677FF',
                  }}
                  className="icon-download-color"
                >
                  <DownloadOutlined />
                  {t('common.download')}
                </Button>
              )}
            </Row>
          </>
        )}
      </Row>
      <Row style={{ marginTop: 12 }}>
        <Upload maxCount={1} name="file" beforeUpload={beforeUpload}>
          <Button
            type="text"
            className="print-button icon-color"
            loading={loading}
            onClick={onClick}
          >
            <UploadOutlined style={{ color: '#333333' }} />
            {t('common.uploadDoc')}
          </Button>
        </Upload>
      </Row>
    </Col>
  );
}

DocumentUpload.propTypes = {
  title: PropTypes.node.isRequired,
  uploaded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func,
  loading: PropTypes.bool,
  downloadDocumentOnClick: PropTypes.func,
  downloadDocument: PropTypes.bool,
  customButton: PropTypes.node,
};

DocumentUpload.defaultProps = {
  beforeUpload: null,
  loading: false,
  downloadDocumentOnClick: null,
  downloadDocument: null,
  customButton: null,
};

export default DocumentUpload;
