import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import phones from '../../../utils/json/phones.json';
import stateOrProvinces from '../../../utils/json/stateOrProvinces.json';
import usePlacesInput from '../../shared/usePlacesInput';
import '../../shipments/thirdPartyShipments/destinationModal.css';

function ShipmentImporter({
  form,
  open,
  setOpen,
  defaultImporter,
  destinationCountryCode,
  syncForm,
}) {
  const { t } = useTranslation();
  const [selectedCountryCode, setSelectedCountryCode] = useState();
  const [currStateOrProvinces, setCurrStateOrProvinces] = useState();
  const { Option } = Select;

  const addressInput = usePlacesInput(
    form,
    setSelectedCountryCode,
    stateOrProvinces
  );

  const useLapImport = () => {
    form.setFieldsValue({
      personName: 'Diego Leal',
      phoneExtension: '+1',
      phoneNumber: '9178681965',
      companyName: 'Lap Imports Corp',
      emailAddress: undefined,
      streetName: '12-01 34th Ave',
      city: 'Long Island City',
      countryCode: 'US',
      stateOrProvinceCode: 'NY',
      postalCode: '11106',
      taxIdNumber: '46-5748738',
      taxIdReason: 'DUNS: 088064687',
    });
    setSelectedCountryCode('US');
  };

  useEffect(() => {
    if (selectedCountryCode) {
      setCurrStateOrProvinces(
        stateOrProvinces.find((row) => row.countryCode === selectedCountryCode)
      );
    } else {
      setCurrStateOrProvinces(
        stateOrProvinces.find(
          (row) => row.countryCode === form.getFieldValue().countryCode
        )
      );
    }
  }, [selectedCountryCode]);

  const getStatesOrProvinces = () => {
    return currStateOrProvinces?.states.map((state) => (
      <Select.Option key={state.code} value={state.code}>
        {state.name}
      </Select.Option>
    ));
  };

  const validatePhoneNumber = (_, value, callback) => {
    const phoneNumberRegex = /^\d+$/;
    if (
      ['US', 'CA'].includes(form.getFieldValue('countryCode')) &&
      value.length !== 10
    ) {
      callback(t('thirdPartyShipments.destination.phoneUsaError'));
    } else if (value && phoneNumberRegex.test(value)) {
      callback();
    } else {
      callback(
        `Por favor ingresa ${!value ? 'un número válido' : 'sólo dígitos'}`
      );
    }
  };

  const onCountryCodeChange = () => {
    const countryCode = form.getFieldValue('countryCode');
    setSelectedCountryCode(countryCode);
  };

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = () => {
    setOpen(false);
  };

  const onOk = () => {
    form.submit();
    syncForm();
  };

  const validatePhoneExtension = (_, value, callback) => {
    if (
      ['US', 'CA'].includes(form.getFieldValue('countryCode')) &&
      value !== '+1'
    ) {
      callback(t('thirdPartyShipments.destination.phoneExtensionUsaError'));
    } else {
      callback();
    }
  };

  const prefixSelector = (
    <Form.Item
      name="phoneExtension"
      noStyle
      rules={[
        { required: true, message: 'Requerido' },
        { required: true, validator: validatePhoneExtension },
      ]}
    >
      <Select
        className="contentOptionsPhone"
        showSearch
        optionFilterProp="children"
      >
        {phones.length > 0 &&
          phones.map((element) => (
            <Select.Option
              className="flag"
              value={`+${element.dial_code}`}
              key={element.code}
            >
              {element.name} (+
              {element.dial_code})
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
  return (
    <Modal
      open={open}
      className="newShipmentImporter"
      width="65%"
      footer={
        <Row justify="space-between">
          <Col>
            <Button
              className="okButtonNewDestinationModal"
              disabled={!defaultImporter || destinationCountryCode !== 'US'}
              onClick={useLapImport}
            >
              {t('thirdPartyShipments.importer.useLAPImport')}
            </Button>
          </Col>
          <Col>
            <Space>
              <Button
                className="cancelButtonNewDestinationModal"
                onClick={onReset}
              >
                {t('common.reset')}
              </Button>
              <Button
                className="okButtonNewDestinationModal"
                onClick={onOk}
                type="primary"
              >
                {t('common.accept')}
              </Button>
            </Space>
          </Col>
        </Row>
      }
      onCancel={() => setOpen(false)}
    >
      <Row>
        <Col span={24}>
          <Title level={5}>{t('externalShipment.step5Title')}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            name="create-warehouse-form"
            layout="vertical"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Row gutter={[16, 20]}>
              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.streetName')}
                  name="streetName"
                  rules={[{ required: true }]}
                  className="labelNewDestinationItem"
                >
                  {addressInput}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t(
                    'warehouses.createForm.address.additionalAddressInfo'
                  )}
                  name="additionalAddressInfo"
                >
                  <Input
                    placeholder={t(
                      'warehouses.createForm.address.additionalAddressInfoPlaceholder'
                    )}
                    showCount
                    maxLength={35}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.postalCode')}
                  name="postalCode"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Ej: 26125" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.city')}
                  name="city"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t(
                      'warehouses.createForm.address.cityPlaceholder'
                    )}
                    showCount
                    maxLength={30}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('warehouses.createForm.address.countryCode')}
                  name="countryCode"
                  rules={[{ required: true }]}
                >
                  <Select
                    showSearch
                    onChange={onCountryCodeChange}
                    filterOption={(input, option) =>
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {phones.map((row) => (
                      <Option key={row.name} value={row.code}>
                        {row.flag} {row.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                {currStateOrProvinces && (
                  <Form.Item
                    label={t(
                      'warehouses.createForm.address.stateOrProvinceCode'
                    )}
                    name="stateOrProvinceCode"
                    rules={[{ required: true }]}
                  >
                    <Select>{getStatesOrProvinces()}</Select>
                  </Form.Item>
                )}
              </Col>

              <Col span={24} />

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.contactName')}
                  name="personName"
                  rules={[{ required: true }, { type: 'string', min: 5 }]}
                >
                  <Input maxLength={70} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.companyName')}
                  name="companyName"
                >
                  <Input maxLength={35} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.email')}
                  name="emailAddress"
                  rules={[
                    {
                      type: 'email',
                      message: t('warehouses.createForm.validEmail'),
                    },
                  ]}
                >
                  <Input maxLength={80} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('warehouses.createForm.phoneNumber')}
                  name="phoneNumber"
                  rules={[{ required: true, validator: validatePhoneNumber }]}
                >
                  <Input addonBefore={prefixSelector} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t('thirdPartyShipments.importer.taxId.number.label')}
                  name="taxIdNumber"
                  rules={[{ required: true }]}
                >
                  <Input maxLength={18} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <Space>
                      {t('thirdPartyShipments.importer.taxId.reason.label')}
                      <Tooltip
                        title={t(
                          'thirdPartyShipments.importer.taxId.reason.tooltip'
                        )}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>
                  }
                  name="taxIdReason"
                  rules={[{ required: true }]}
                >
                  <Input
                    maxLength={30}
                    placeholder={t(
                      'thirdPartyShipments.importer.taxId.reason.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

ShipmentImporter.propTypes = {
  form: PropTypes.instanceOf(Form).isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  destinationCountryCode: PropTypes.string.isRequired,
  defaultImporter: PropTypes.bool.isRequired,
  syncForm: PropTypes.func.isRequired,
};

export default ShipmentImporter;
