import {
  Button,
  Card,
  Col,
  Dropdown,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
} from 'antd';
import {
  CodeSandboxOutlined,
  DeleteOutlined,
  PlusOutlined,
  DownOutlined,
  EditOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as queryString from 'query-string';
import shipmentAPI from '../../api/shipment';
import ShipmentTypeTag from './components/ShipmentTypeTag';
import ShipmentStatusTag from './components/ShipmentStatusTag';
import ShipmentsTableFilters from './components/ShipmentsTableFilters';
import { mixPanelCreateEvent } from '../../utils/functions';
import restockOrdersAPI from '../../api/restock-orders';
import restockV2024 from '../../api/restockV2024';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';
import couriersAPI from '../../api/courier';
import thirdPartyShipmentAPI from '../../api/thirdPartyShipment';
import fulfillmentAPI from '../../api/fulfillment';
import './shipments.css';
import ShipmentTrackingModal from './components/ShipmentTrackingModal';

function ShipmentsTable() {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const language = useSelector((store) => store.Session.language);
  const qs = queryString.parse(window.location.search);
  const history = useHistory();
  const [fboCompanyOptions, setFboCompanyOptions] = useState([]);
  const [courierCredentials, setCourierCredentials] = useState();
  const [dataSource, setDataSource] = useState();
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showShipmentTrackingModal, setShowShipmentTrackingModal] =
    useState(false);
  const [shipmentTrackingInfo, setShipmentTrackingInfo] = useState({});

  const getCourierNamesByCompany = async () => {
    try {
      const { data } = await fulfillmentAPI.getFboCompanyNames();
      const finalOpts = [];

      data.forEach((companyName) => {
        const alreadyExists = finalOpts.find(
          (opt) => opt.value === companyName.split(' ')[0]
        );

        if (!alreadyExists) {
          if (companyName.includes('Amazon')) {
            finalOpts.push({
              value: 'Amazon',
              label: 'Amazon',
            });
          } else {
            finalOpts.push({
              value: companyName,
              label: companyName,
            });
          }
        }
      });
      setFboCompanyOptions(finalOpts);
    } catch (error) {
      openNotification({
        status: false,
        content: `${t(
          'shipments.errors.getCourierNamesByCompany'
        )}: ${getErrorMessage(error)}`,
      });
    }
  };

  const getCourierCredentials = async () => {
    try {
      const courierCredentialResponse = await couriersAPI.getCourierCredentials(
        10,
        1
      );
      setCourierCredentials(courierCredentialResponse.data);
    } catch (error) {
      openNotification({
        status: false,
        content: `${t(
          'shipments.errors.getCourierNamesByCompany'
        )}: ${getErrorMessage(error)}`,
      });
    }
  };

  const createRestockOrder = async (fboCompanyName) => {
    setLoadingButton(true);
    try {
      const companyId = session.userInfo.commercial_partner_id[0];
      const response = await restockOrdersAPI.create(companyId, fboCompanyName);
      const order = response.data;
      history.push(`envios/restock/${order.id}`);
    } catch (error) {
      openNotification({
        status: false,
        content: `${t(
          'shipments.errors.createRestockOrder'
        )}: ${getErrorMessage(error)}`,
      });
    }
    setLoadingButton(false);
  };

  const deleteRestockOrder = async (id) => {
    try {
      await restockV2024.cancelShipment(id);
      openNotification({
        status: true,
        content: t('shipments.errors.deleteRestockOrder.success'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: `${t(
          'shipments.errors.deleteRestockOrder.error'
        )}: ${getErrorMessage(error)}`,
      });
    }
  };

  const cancelShipment = async (record) => {
    const promises = [];
    record.courierShipments.forEach((courierShipment) => {
      if (!['Cancelled', 'Error'].includes(courierShipment.status)) {
        promises.push(
          couriersAPI.cancelCourierShipment(
            courierShipment.courierCompanyName,
            courierShipment.id
          )
        );
      }
    });
    try {
      if (promises.length === 0 && record.type === 'ThirdPartyShipment') {
        await thirdPartyShipmentAPI.cancelThirdPartyShipmentById(record.id);
      }
      await Promise.all(promises);
      openNotification({
        status: true,
        content: t('shipments.errors.cancelShipment.success'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    }
  };

  const createThirdPartyShipment = async () => {
    try {
      const response = await thirdPartyShipmentAPI.createThirdPartyShipment();
      history.push({
        pathname: `/envios/a-terceros/${response.data.id}`,
      });
    } catch (error) {
      openNotification({
        status: false,
        content: `${t(
          'shipments.errors.createThirdPartyShipment'
        )}: ${getErrorMessage(error)}`,
      });
    }
  };

  const onClickEdit = (record) => {
    mixPanelCreateEvent('Action Button', {
      action: 'Button edit shipment',
      id: record.id,
      type: record.type,
    });
    switch (record.type) {
      case 'RestockOrder':
        history.push(`envios/restock/${record.id}`);
        break;
      case 'SaleOrder':
        break;
      case 'ThirdPartyShipment':
        history.push(`envios/a-terceros/${record.id}`);
        break;
      default:
        break;
    }
  };

  const onClickTrack = (record) => {
    mixPanelCreateEvent('Action Button', {
      action: 'Button track shipment',
      id: record.id,
      type: record.type,
    });
    let type;
    if (record.type === 'ThirdPartyShipment') {
      type = 'TERCEROS';
    } else if (record.type === 'RestockOrder') {
      type = 'RESTOCK';
    } else {
      type = 'PEDIDO';
    }
    const date = new Date(record.createdAt).toLocaleDateString(language, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    const [day, month, year] = date.split(' ');
    const shipmentName = `[${type}] ${day}-${month.toUpperCase()}-${year} - ${
      record.name
    }`;
    const fedexTrackingNumbers = record.courierShipments.reduce(
      (trackNumbers, shipment) =>
        shipment.courierCompanyName === 'Fedex'
          ? `${trackNumbers}${trackNumbers ? ',' : ''}${
              shipment.masterTrackingNumber
            }`
          : trackNumbers,
      ''
    );
    setShowShipmentTrackingModal(true);
    setShipmentTrackingInfo({
      name: shipmentName,
      statuses: record.courierShipments.map(
        (courierShipment) => courierShipment.status
      ),
      trackingNumbers: fedexTrackingNumbers,
      id: record.id,
    });
  };

  const typeMapWithIcon = {
    RestockOrder: (
      <ShipmentTypeTag
        text={t('shipments.types.restock')}
        icon={<CodeSandboxOutlined />}
      />
    ),
    SaleOrder: (
      <ShipmentTypeTag
        text={t('shipments.types.saleOrder')}
        icon={<ShoppingCartOutlined />}
      />
    ),
    ThirdPartyShipment: (
      <ShipmentTypeTag
        text={t('shipments.types.thirdPartyShipments')}
        icon={<TeamOutlined />}
      />
    ),
  };

  const getTableRows = async (queryStringParams) => {
    const query = { ...queryStringParams };
    try {
      setLoadingTable(true);
      const response = await shipmentAPI.getShipmentsTable(query);
      setDataSource(response.data.result);
    } catch (error) {
      /* empty */
    }
    setLoadingTable(false);
  };

  const onClickDelete = async (record) => {
    const params = queryString.parse(window.location.search);
    switch (record.type) {
      case 'RestockOrder':
        mixPanelCreateEvent('Action Button', {
          action: 'Button delete shipment',
          id: record.id,
          type: record.type,
        });
        await deleteRestockOrder(record.id);
        await getTableRows(params);
        break;
      case 'SaleOrder':
      case 'ThirdPartyShipment':
        await cancelShipment(record);
        await getTableRows(params);
        break;
      default:
        break;
    }
  };

  // TO DO: Actions
  // first iicon: onclick redirect to step 4 of restock: see documents and related information
  const columns = [
    {
      title: t('shipments.columns.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (value, record) => {
        let type;
        if (record.type === 'ThirdPartyShipment') {
          type = 'TERCEROS';
        } else if (record.type === 'RestockOrder') {
          type = 'RESTOCK';
        } else {
          type = 'PEDIDO';
        }
        const date = new Date(record.createdAt).toLocaleDateString(language, {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
        const [day, month, year] = date.split(' ');
        return `[${type}] ${day}-${month.toUpperCase()}-${year} - ${value}`;
      },
    },
    {
      title: t('shipments.columns.type'),
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      render: (value) => {
        return typeMapWithIcon[value];
      },
    },
    {
      title: t('shipments.columns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      render: (value) =>
        new Date(value).toLocaleDateString(language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    },
    {
      title: t('shipments.columns.origin'),
      dataIndex: 'origin',
      key: 'origin',
      align: 'left',
    },
    {
      title: t('shipments.columns.destination'),
      dataIndex: 'destination',
      key: 'destination',
      align: 'left',
    },
    {
      title: t('shipments.columns.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      render: (value, record) => {
        return <ShipmentStatusTag status={value} type={record.type} />;
      },
    },
    {
      title: t('shipments.columns.actions'),
      key: 'actions',
      align: 'left',
      render: (_value, record) => {
        const isDeleteDisabled = !(
          record.status === 'Draft' ||
          record.status === 'Working' ||
          record.status === 'ReadyToShip' ||
          record.status === 'Shipped'
        );
        const isEditDisabled =
          !(record.status === 'Draft' || record.status === 'Working') ||
          record.type === 'SaleOrder';
        const isViewDisabled = !isEditDisabled;
        return (
          <Row className="shipment-actions">
            <Col>
              <Tooltip
                placement="topLeft"
                title={t('shipments.buttons.checklist')}
              >
                <Button
                  ghost
                  icon={<FileDoneOutlined />}
                  disabled={isViewDisabled}
                  onClick={() => onClickEdit(record)}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="topLeft" title={t('shipments.buttons.track')}>
                <Button
                  ghost
                  icon={
                    <EnvironmentOutlined onClick={() => onClickTrack(record)} />
                  }
                  disabled={
                    !(
                      record.courierShipments.filter(
                        (courierShipment) =>
                          !['Cancelled', 'Error'].includes(
                            courierShipment.status
                          )
                      ).length > 0
                    )
                  }
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="topLeft" title={t('shipments.buttons.edit')}>
                <Button
                  ghost
                  icon={<EditOutlined />}
                  onClick={() => onClickEdit(record)}
                  disabled={isEditDisabled}
                />
              </Tooltip>
            </Col>
            <Col>
              {isDeleteDisabled ? (
                <Tooltip
                  placement="topLeft"
                  title={t('shipments.buttons.cancel')}
                >
                  <Button ghost icon={<DeleteOutlined />} disabled />
                </Tooltip>
              ) : (
                <Popconfirm
                  className="confirmDelete"
                  icon={<ExclamationCircleOutlined />}
                  title={t('shipments.buttons.cancelWarning')}
                  onConfirm={() => {
                    onClickDelete(record);
                  }}
                  okText="Sí"
                  cancelText="No"
                >
                  <Tooltip
                    placement="topLeft"
                    title={t('shipments.buttons.cancel')}
                  >
                    <Button ghost icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];
  const buttonOptions = [
    {
      label: t('shipments.types.restock'),
      key: 'RestockOrder',
    },
    {
      label: t('shipments.types.saleOrder'),
      key: 'SaleOrder',
    },
    {
      label: t('shipments.types.toThirdPartyShipments'),
      key: 'ThirdPartyShipment',
      disabled: courierCredentials?.length === 0,
    },
  ];
  const handleMenuClick = async (e) => {
    const amazonExists = fboCompanyOptions.find(
      (opt) => opt.value === 'Amazon'
    );
    switch (e.key) {
      case 'RestockOrder':
        if (amazonExists) await createRestockOrder('Amazon');
        else
          openNotification({
            status: false,
            content: t('shipments.errors.noFulfillmentCenter'),
          });
        break;
      case 'ThirdPartyShipment':
        await createThirdPartyShipment();
        break;
      case 'SaleOrder':
        history.push('/pedidos');
        break;
      default:
        break;
    }
  };

  const buttonMenuProps = {
    items: buttonOptions,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    getCourierNamesByCompany();
    getCourierCredentials();
  }, [session]);

  return (
    <div
      className="content-div container-shipments"
      style={{
        backgroundColor: '#f9f9f9',
        paddingBottom: 0,
        paddingTop: 70,
        paddingLeft: 20,
        paddingRight: 15,
      }}
    >
      <Row className="home-listing-main-row listings-main">
        <Col xs={24} md={24} lg={24} xl={24} span={12}>
          <Card className="ventas-home-listing-card">
            <Row>
              <Col flex={3}>
                <ShipmentsTableFilters
                  shipmentType={qs?.type}
                  startDate={qs?.startDate}
                  endDate={qs?.endDate}
                  status={qs?.statuses}
                  callback={getTableRows}
                />
              </Col>
              <Col flex={2} className="shipment-new-button">
                <Dropdown className="btn-basic-green" menu={buttonMenuProps}>
                  <Button loading={loadingButton}>
                    <Space>
                      <PlusOutlined />
                      {t('shipments.buttons.newShipment')}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} lg={24} xl={24} span={12}>
          <Card
            className="ventas-home-listing-card"
            style={{ marginTop: 10, paddingBottom: 10 }}
          >
            <Row>
              <Col xs={24} span={24} className="intro-filters">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={loadingTable}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ShipmentTrackingModal
        isModalOpen={showShipmentTrackingModal}
        setIsModalOpen={setShowShipmentTrackingModal}
        shipmentTrackingInfo={shipmentTrackingInfo}
        setShipmentTrackingInfo={setShipmentTrackingInfo}
      />
    </div>
  );
}

export default ShipmentsTable;
