import {
  Avatar,
  Col,
  Collapse,
  Form,
  Image,
  Row,
  Space,
  Select,
  Table,
  Tooltip,
  Typography,
  InputNumber,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import {
  DownOutlined,
  ExclamationCircleTwoTone,
  PictureOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pallets from './Pallets';
import CreateBoxModal from '../../couriers/Fedex/components/CreateBoxModal';

function CasePackedAndKnownBoxInformationTable({
  shipmentId,
  order,
  items,
  boxes,
  setBoxes,
  pallets,
  setPallets,
  unit,
  formRefs,
  shipmentIndex,
  convertWeightUnit,
  disableForm,
}) {
  const { Panel } = Collapse;
  const [dataSource, setDataSource] = useState();
  const [isCreateBoxModalOpen, setIsCreateBoxModalOpen] = useState(false);
  const [boxModalInfo, setBoxModalInfo] = useState({});
  const [totalFob, setTotalFob] = useState(null);
  const [totalBoxQuantity, setTotalBoxQuantity] = useState(null);
  const [isFood, setIsFood] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const unitMeasure = unit === 'cm' ? 'CM' : 'IN';
  const weightUnit = unit === 'cm' ? 'kg' : 'lb';
  const maxBoxWeightUnit = { CM: 50, IN: 110 };
  const [newBoxFormLocation, setNewBoxFormLocation] = useState(null);
  const [unitsPerPackValues, setUnitsPerPackValues] = useState({});

  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  const expandIcon = ({ isActive }) =>
    isActive ? <DownOutlined /> : <UpOutlined />;

  const createBoxCallback = (newBox) => {
    setBoxes([...boxes, newBox]);
    form.setFieldValue(newBoxFormLocation, newBox.id);
    setNewBoxFormLocation(null);
  };

  const onBoxSelectChange = (value, data) => {
    if (value === 'newBox') {
      setBoxModalInfo({
        shipmentId,
        name: '',
        productIndex: data.key,
      });
      setIsCreateBoxModalOpen(true);
    }
  };

  const formatDataSource = () => {
    let fob = 0;
    let boxQuantity = 0;
    const data = items?.map((item) => {
      const product = order?.products?.find(
        (prod) => item.SellerSKU === prod.product.defaultCode
      );
      if (product.expirationDate) {
        setIsFood(true);
      }
      let orderBoxes = order.restockOrderBoxes.filter(
        (box) => box.shipmentId === shipmentId
      );
      if (orderBoxes.length === 0) {
        orderBoxes = order.restockOrderBoxes.filter(
          (box) => box.shipmentId === order.amazonId
        );
      }
      const box = orderBoxes.find((orderBox) => {
        const boxedProduct = orderBox.products.find(
          (p) => p.product.defaultCode === product.product.defaultCode
        );
        return boxedProduct;
      });
      const selectedBox = {
        id: box?.box?.id || null,
        name: box?.box?.name || null,
        length: box?.box?.length || null,
        width: box?.box?.width || null,
        height: box?.box?.height || null,
      };
      let image;
      if (
        product?.product?.attributes &&
        Array.isArray(product?.product?.attributes)
      )
        image = product?.product?.attributes.main_offer_image_locator
          ? product?.product?.attributes.main_offer_image_locator[0]
              ?.media_location
          : null;
      else
        image = product?.product?.attributes.main_offer_image_locator
          ? product?.product?.attributes.main_offer_image_locator[0]
              ?.media_location
          : null;

      if (product?.product?.productTemplate.unitFOB && item.Quantity) {
        fob += product.product.productTemplate.unitFOB * item.Quantity;
      }
      boxQuantity += item.Quantity / product.unitsPerPack;
      return {
        id: product?.product?.id,
        name: product?.product?.title,
        sku: product?.product?.defaultCode,
        quantity: item.Quantity,
        unitsPerPack: product?.unitsPerPack,
        selectedBox,
        numberOfBoxes: null,
        image,
        expirationDate: product.expirationDate,
        weight: box?.weight || null,
        productWeight: product?.product?.weight,
        productWeightUnit: product?.product?.weightUnity,
      };
    });
    setDataSource(data);
    const dataUnitsPerPack = {};
    data.forEach((item) => {
      dataUnitsPerPack[item.id] = item.unitsPerPack;
    });
    setUnitsPerPackValues(dataUnitsPerPack);
    setTotalFob(fob);
    setTotalBoxQuantity(boxQuantity);
  };

  useEffect(() => {
    if (!dataSource && boxes) {
      formatDataSource();
    }
  }, [boxes]);

  useEffect(() => {
    formatDataSource();
  }, [unit]);

  const setFormRef = (formName, formRef) => {
    // eslint-disable-next-line no-param-reassign
    formRefs.current = {
      ...formRefs.current,
      [formName]: formRef,
    };
  };

  const boxesValidator = (rule) => {
    const key = rule.field.split('-')[1];
    const quantity = form.getFieldValue(`quantity-${key}`);
    const unitsPerPack = form.getFieldValue(`unitsPerPack-${key}`);
    if (quantity !== undefined && unitsPerPack !== undefined) {
      if (quantity / unitsPerPack !== Math.floor(quantity / unitsPerPack)) {
        return Promise.reject(new Error('Inválido'));
      }
    }
    return Promise.resolve();
  };

  const handleUnitsPerPackChange = (rowId, value) => {
    setUnitsPerPackValues((prevValues) => ({
      ...prevValues,
      [rowId]: value,
    }));
  };

  const columns = [
    {
      title: 'Producto',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => (
        <>
          <Form.Item
            name={['products', shipmentId, index, 'sku']}
            hidden
            initialValue={record.sku}
          />

          <Row align="middle" justify="start">
            <Col span={24}>
              <Space>
                {record?.image ? (
                  <Avatar
                    style={{ width: 40, height: 40 }}
                    shape="square"
                    className="home-listing-table-avatar"
                    src={<Image src={`${record?.image}`} />}
                  />
                ) : (
                  <Avatar
                    style={{ width: 40, height: 40 }}
                    shape="square"
                    className="home-listing-table-avatar"
                    icon={<PictureOutlined />}
                  />
                )}
                <Row>
                  <Col span={24}>
                    <Tooltip placement="topLeft" title={text}>
                      <Typography.Text style={{ color: '#67A4FF' }}>
                        {text}
                      </Typography.Text>
                    </Tooltip>
                  </Col>
                  <Col span={24}>
                    <Tooltip title={text}>SKU: {record.sku}</Tooltip>
                  </Col>
                </Row>
              </Space>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: 'Unidades totales',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '12%',
      align: 'center',
      render: (text, record, index) => (
        <Form.Item
          name={['products', shipmentId, index, 'quantity']}
          initialValue={text}
        >
          <Text>{record.quantity}</Text>
        </Form.Item>
      ),
    },
    {
      title: 'Unidades por caja',
      dataIndex: 'unitsPerPack',
      key: 'unitsPerPack',
      width: '12%',
      align: 'center',
      render: (_text, record, index) => (
        <Form.Item
          label=""
          className="input-form-margin-bottom"
          name={['products', shipmentId, index, 'unitsPerPack']}
          dependencies={[`quantity-${record.id}`]}
          initialValue={record.unitsPerPack}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
            { validator: (rule, value) => boxesValidator(rule, value) },
          ]}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <InputNumber
            name={`unitsPerPack-${record.id}`}
            min={1}
            controls={false}
            onChange={(value) => handleUnitsPerPackChange(record.id, value)}
            style={{ width: 60 }}
            disabled={disableForm}
          />
        </Form.Item>
      ),
    },

    {
      title: 'Número de cajas',
      dataIndex: 'numberOfBoxes',
      key: 'numberOfBoxes',
      width: '12%',
      align: 'center',
      render: (_text, row) => {
        const { quantity } = row;
        const unitsPerPack = unitsPerPackValues[row.id];

        if (quantity !== undefined && unitsPerPack !== undefined) {
          const division = quantity / unitsPerPack;
          return (
            <Text>
              {division === Math.ceil(division)
                ? `${division.toString()} 📦`
                : 'Inválido'}
            </Text>
          );
        }
        if (row.quantity && row.unitsPerPack) {
          const division = row.quantity / row.unitsPerPack;
          return (
            <Text>
              {division === Math.ceil(division)
                ? `${division.toString()} 📦`
                : 'Inválido'}
            </Text>
          );
        }
        return '';
      },
    },

    {
      title: 'Tamaño Caja',
      dataIndex: 'selectedBox',
      key: 'selectedBox',
      align: 'center',
      width: '25%',
      render: (_text, record, index) => (
        <Form.Item
          name={['products', shipmentId, index, 'selectedBox']}
          initialValue={record.selectedBox.id}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
          ]}
        >
          <Select
            disabled={disableForm}
            placeholder="Seleccionar caja"
            onChange={(value, data) => {
              setNewBoxFormLocation([
                'products',
                shipmentId,
                index,
                'selectedBox',
              ]);
              onBoxSelectChange(value, data);
            }}
          >
            {boxes &&
              boxes.map((box) => (
                <Select.Option key={box.id} value={box.id}>
                  {`${box.name} (${box.length} x ${box.width} x ${box.height})`}
                </Select.Option>
              ))}
            <Select.Option key="newBox" value="newBox">
              <PlusOutlined /> Nueva información de caja
            </Select.Option>
          </Select>
        </Form.Item>
      ),
    },
    {
      title: 'Peso Unitario Producto',
      dataIndex: 'productWeight',
      key: 'productWeight',
      width: '20%',
      align: 'center',
      render: (text, record, index) => (
        <Form.Item
          initialValue={convertWeightUnit(record.productWeightUnit, unit, text)}
          name={['products', shipmentId, index, 'productWeight']}
        >
          <InputNumber
            min={0}
            defaultValue={convertWeightUnit(
              record.productWeightUnit,
              unit,
              text
            )}
            addonAfter={weightUnit}
            formatter={formatterNumber}
            parser={parserNumber}
            controls={false}
            disabled={disableForm}
          />
        </Form.Item>
      ),
    },
    {
      title: 'Peso Unitario Caja',
      dataIndex: 'selectedBox',
      key: 'selectedBox',
      width: '20%',
      align: 'center',
      render: (_text, record, index) => {
        const unitsPerPack = unitsPerPackValues[record.id];
        const minWeight =
          convertWeightUnit(
            record.productWeightUnit,
            unit,
            record.productWeight
          ) * unitsPerPack;
        return (
          <Form.Item
            name={['products', shipmentId, index, 'weight']}
            initialValue={record.weight}
            rules={[
              {
                required: true,
                message: 'Requerido',
              },
              {
                message: `${t(
                  'orders.newOrder.boxes.errors.productBoxWeight'
                )} ${minWeight} ${weightUnit}`,
                validator: (_, value) => {
                  if (value >= minWeight) return Promise.resolve();
                  return Promise.reject();
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              max={maxBoxWeightUnit[unitMeasure]}
              formatter={formatterNumber}
              parser={parserNumber}
              controls={false}
              disabled={disableForm}
            />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Collapse
      className="boxes-shipment"
      expandIcon={expandIcon}
      defaultActiveKey={[shipmentId]}
    >
      <Panel
        key={shipmentId}
        header={
          <Row>
            <Col span={12}>
              <Typography.Text strong>{`Código de grupo ${
                shipmentIndex + 1
              } de envío de cajas`}</Typography.Text>
            </Col>
            <Col span={12} className="table-header-boxes-shipment-second-colum">
              <Typography.Text style={{ marginRight: 5 }}>
                Cajas:
              </Typography.Text>
              <Typography.Text strong>{totalBoxQuantity}</Typography.Text>
              <Divider type="vertical" style={{ height: '100%' }} />
              <Typography.Text style={{ marginRight: 5 }}>
                FOB total:
              </Typography.Text>
              <Typography.Text strong>
                US ${parseFloat(totalFob).toFixed(2) || 'calculando'}
              </Typography.Text>
            </Col>
          </Row>
        }
        style={{ backgroundColor: 'rgba(237, 245, 255, 1)' }}
      >
        {(totalFob > 2500 || isFood) && order.destination === 'US' && (
          <Row
            style={{
              padding: 12,
              backgroundColor: '#FCF4D6',
              borderRadius: 4,
              marginBottom: 32,
              border: 'solid 1px rgb(250, 173, 20, .5)',
            }}
            borderRadius
            align="top"
          >
            <Col>
              <ExclamationCircleTwoTone
                twoToneColor="rgb(250, 173, 20)"
                style={{ fontSize: '16px' }}
              />
            </Col>
            <Col span={23} style={{ maxWidth: 700 }}>
              <ul>
                {isFood ? (
                  <li>{t('orders.newOrder.boxes.importer.fda')}</li>
                ) : (
                  <li>{t('orders.newOrder.boxes.importer.price')}</li>
                )}
                {totalFob > 3000 && (
                  <li style={{ marginTop: '8px' }}>
                    {t('orders.newOrder.boxes.customsBroker')}
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24} sm={24} md={24}>
            {dataSource && (
              <Form
                ref={(actualForm) => {
                  setFormRef(shipmentId, actualForm);
                }}
                form={form}
                name="productAssignmentForm"
              >
                <div style={{ marginBottom: 15 }}>
                  <Typography.Text className="restock-boxes-table-title">
                    Define el tamaño y el peso unitario para cada caja
                  </Typography.Text>
                </div>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  className="listing-shipment-table"
                />
              </Form>
            )}
            {isCreateBoxModalOpen && boxModalInfo && (
              <CreateBoxModal
                showCreateBoxModal={isCreateBoxModalOpen}
                setShowCreateBoxModal={setIsCreateBoxModalOpen}
                unitMeasure={unitMeasure}
                callback={createBoxCallback}
              />
            )}
            {order.isPalletized && (
              <Pallets
                form={form}
                pallets={pallets}
                setPallets={setPallets}
                shipmentId={shipmentId}
                unit={unit}
              />
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

CasePackedAndKnownBoxInformationTable.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  order: PropTypes.shape({
    amazonId: PropTypes.string,
    restockOrderBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        box: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        weight: PropTypes.number,
      })
    ).isRequired,
    isPalletized: PropTypes.bool.isRequired,
    unity: PropTypes.string.isRequired,
    products: PropTypes.instanceOf(Object),
    state: PropTypes.string,
    isPack: PropTypes.bool,
    name: PropTypes.string,
    destination: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      FulfillmentNetworkSKU: PropTypes.string,
      Quantity: PropTypes.number,
      SellerSKU: PropTypes.string,
    })
  ).isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      weight: PropTypes.string,
    })
  ).isRequired,
  setBoxes: PropTypes.func.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      pallets: PropTypes.arrayOf({
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        weight: PropTypes.number,
      }),
      shipmentId: PropTypes.string,
    })
  ).isRequired,
  setPallets: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  formRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  shipmentIndex: PropTypes.number.isRequired,
  convertWeightUnit: PropTypes.func.isRequired,
  disableForm: PropTypes.bool.isRequired,
};
export default CasePackedAndKnownBoxInformationTable;
