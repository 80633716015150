import {
  Button,
  Col,
  Collapse,
  Drawer,
  List,
  Row,
  Select,
  Space,
  Spin,
  Timeline,
  Upload,
} from 'antd';
import Search from 'antd/lib/input/Search';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import categoryApi from '../../../api/aws-category';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import listingsApi from '../../../api/listing';

function ExportProductsModal({
  marketplaceToExport,
  setMarketplaceToExport,
  selectedListings,
  originMarketplace,
  all,
  total,
}) {
  const { t } = useTranslation();

  const [matchedCategories, setMatchedCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [allCategoriesMatched, setAllCategoriesMatched] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [excludePublished, setExcludePublished] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState({});
  const [categories, setCategories] = useState({});
  const [categorization, setCategorization] = useState({
    origin: {},
    destination: {},
  });
  const [skusFilter, setSkusFilter] = useState({});
  const [activeSku, setActiveSku] = useState(null);

  const PRODUCT_LIMIT = 10000;

  const SEARCH_PLACEHOLDER = [
    'Amazon',
    'Ebay',
    'Walmart',
    'Amazon Canada',
    'Ebay Canada',
  ].includes(marketplaceToExport)
    ? t('catalogue.publishProductsModal.searchCategoriesPlaceholder')
    : t('catalogue.publishProductsModal.spanishSearchCategoriesPlaceholder');

  const CATEGORY_NOT_ALLOWED = {
    status: false,
    content: t('products.errors.categoryNotAllowed'),
    helpUrl:
      'https://ayuda.lapmarketplace.com/hc/es-cl/articles/16428729142555',
  };

  const handleOnClose = () => {
    setExcludePublished(null);
    setMarketplaceToExport(null);
    setMatchedCategories(null);
    setSearchResults([]);
    setSelectedCategoryId({});
    setCategorization({
      origin: {},
      destination: {},
    });
    setCategories({});
    setSkusFilter({});
    setActiveSku(null);
  };

  useEffect(() => {
    if (marketplaceToExport && total > PRODUCT_LIMIT) {
      setMarketplaceToExport(null);
      openNotification({
        status: false,
        content: t('catalogue.publishProductsModal.limitError', {
          limit: PRODUCT_LIMIT,
        }),
      });
    }
  }, [marketplaceToExport]);

  useEffect(() => {
    setAllCategoriesMatched(
      Object.values(categorization.destination)?.every(
        (c) => c !== null && c !== undefined
      )
    );
  }, [categorization]);

  const onCategorize = async (newExcludePublished) => {
    try {
      setExcludePublished(newExcludePublished);
      setLoading(true);
      const payload = {
        defaultCodes: selectedListings,
        originMarketplace,
        destinationMarketplace: marketplaceToExport,
      };
      if (all) payload.all = all;
      if (newExcludePublished) payload.excludePublished = newExcludePublished;
      const { data } = await categoryApi.getMatchedCategories(payload);
      setMatchedCategories(data);
      const newCategorization = { origin: {}, destination: {} };
      Object.values(data.originCategories).forEach((c) => {
        c.skus.forEach((s) => {
          newCategorization.origin[s] = c.categoryId;
        });
      });
      const newCategories = {};
      Object.values(data.destinationCategories)?.forEach((c) => {
        if (c.categoryId) {
          newCategories[c.categoryId] = c.categoryName;
        }
        c.skus.forEach((s) => {
          newCategorization.destination[s] = c.categoryId;
        });
      });
      setCategories(newCategories);
      setCategorization(newCategorization);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
      setMarketplaceToExport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleProductTypeSearch = (newValue) => {
    if (newValue) {
      setFetchingOptions(true);
      categoryApi
        .getByMarketplaceAndSearch(marketplaceToExport, newValue)
        .then((response) => {
          if (!marketplaceToExport?.includes('Amazon')) {
            setSearchResults(
              response.data.map((item) => ({
                value: {
                  productType: item.identifier,
                  completeName: item.completeName,
                },
                text: item.completeName,
              }))
            );
          } else {
            const processedResponse = response.data?.map((item) => ({
              value: JSON.stringify({
                productType: item.productType,
                completeName: item.completeName,
                supported: item.supported,
              }),
              label: item.completeName,
              text: item.completeName,
              supported: item.supported,
            }));
            setSearchResults([
              {
                label: t('catalogue.publishProductsModal.uploadLAP'),
                options: processedResponse.filter(
                  (category) => category.supported === 1
                ),
              },
              {
                label: t('catalogue.publishProductsModal.uploadAmazon'),
                options: processedResponse.filter(
                  (category) => category.supported === 0
                ),
              },
            ]);
          }
          setFetchingOptions(false);
        })
        .catch(() => setFetchingOptions(false));
    } else {
      setSearchResults([]);
    }
  };

  const debouncedHandleProductTypeSearch = debounce(
    handleProductTypeSearch,
    500
  );

  const selectOptions = () => {
    if (!marketplaceToExport?.includes('Amazon')) {
      return (searchResults || []).map((d) => ({
        value: JSON.stringify(d.value),
        label: d.text,
      }));
    }
    return searchResults || [];
  };

  const handleDownload = async () => {
    const skusByCategory = {};
    Object.entries(categories).forEach(([key, value]) => {
      skusByCategory[key] = {
        categoryId: key,
        categoryName: value,
        skus: [],
      };
    });
    Object.entries(categorization.destination).forEach(([key, value]) => {
      skusByCategory[value].skus.push(key);
    });
    const body = {
      originMarketplace,
      destinationMarketplace: marketplaceToExport,
      skusByCategory: Object.values(skusByCategory).filter(
        (c) =>
          c.categoryId !== null &&
          c.categoryId !== undefined &&
          c.skus.length > 0
      ),
    };
    setLoading(true);
    return listingsApi
      .buildExportSheet(body)
      .catch((error) => {
        if (
          error.response.status === 400 &&
          marketplaceToExport?.includes('Amazon')
        )
          openNotification(CATEGORY_NOT_ALLOWED);
        else
          openNotification({
            status: false,
            content: getErrorMessage(error),
          });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const beforeUpload = (file) => {
    setUploadFile(file);
    return false;
  };

  const handleUpload = async () => {
    setLoading(true);
    try {
      listingsApi.uploadProductsSheet(uploadFile).then(() => {
        window.location = '/imports';
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGroupProductTypeChange = () => {
    if (selectedCategoryId.destination.supported === 0) {
      openNotification(CATEGORY_NOT_ALLOWED);
      return;
    }

    const newCategorization = {
      ...categorization,
      destination: {
        ...categorization.destination,
      },
    };

    matchedCategories.originCategories[
      selectedCategoryId.origin ?? 'NOT_FOUND'
    ].skus.forEach((s) => {
      newCategorization.destination[s] =
        selectedCategoryId.destination.productType;
    });

    const newCategories = { ...categories };
    if (!newCategories[selectedCategoryId.destination.productType]) {
      newCategories[selectedCategoryId.destination.productType] =
        selectedCategoryId.destination.completeName;
    } else {
      const currentName = new Set(
        newCategories[selectedCategoryId.destination.productType].split(' {*} ')
      );
      currentName.add(selectedCategoryId.destination.completeName);
      newCategories[selectedCategoryId.destination.productType] =
        Array.from(currentName).join(' {*} ');
    }

    setCategories(newCategories);
    setCategorization(newCategorization);
  };

  const handleSkuProductTypeChange = (newValue, sku) => {
    if (newValue.supported === 0) {
      openNotification(CATEGORY_NOT_ALLOWED);
      return;
    }

    const newCategorization = {
      ...categorization,
      destination: {
        ...categorization.destination,
        [sku]: newValue.productType,
      },
    };

    const newCategories = { ...categories };
    if (!newCategories[newValue.productType]) {
      newCategories[newValue.productType] = newValue.completeName;
    } else {
      const currentName = new Set(
        newCategories[newValue.productType].split(' {*} ')
      );
      currentName.add(newValue.completeName);
      newCategories[newValue.productType] =
        Array.from(currentName).join(' {*} ');
    }

    setCategorization(newCategorization);
    setCategories(newCategories);
  };

  return (
    <Drawer
      title={t('catalogue.exportProductsModal', {
        originMarketplace,
        marketplaceToExport,
      })}
      placement="right"
      onClose={handleOnClose}
      open={marketplaceToExport && total > 0 && total < PRODUCT_LIMIT}
      width="75%"
    >
      <Spin spinning={loading}>
        <Timeline>
          <Timeline.Item>
            <Space size="middle">
              <Space>
                <Button
                  size="small"
                  type={excludePublished === true ? 'primary' : 'secondary'}
                  icon={<CheckOutlined />}
                  onClick={() => onCategorize(true)}
                />
                <Button
                  size="small"
                  type={excludePublished === false ? 'primary' : 'secondary'}
                  icon={<CloseOutlined />}
                  onClick={() => onCategorize(false)}
                />
              </Space>
              {t('catalogue.publishProductsModal.excludePublished', {
                marketplace: marketplaceToExport,
              })}
            </Space>
          </Timeline.Item>
          {matchedCategories ? (
            <>
              <Timeline.Item>
                <Col span={12}>
                  {t('catalogue.publishProductsModal.destinationCategories')}
                </Col>
                <Row
                  justify="space-between"
                  gutter={[16, 16]}
                  style={{
                    maxHeight: 'calc(100vh - 342px)',
                    overflowY: 'auto',
                    marginBottom: 20,
                  }}
                >
                  <Col
                    span={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {originMarketplace}
                  </Col>
                  <Col span={1} />
                  <Col
                    span={10}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {marketplaceToExport}
                  </Col>
                  <Col span={2} />
                  <Col span={10}>
                    <Select
                      id="origin-category-select"
                      showSearch
                      defaultActiveFirstOption={false}
                      placeholder={t(
                        'catalogue.publishProductsModal.selectOriginCategory'
                      )}
                      showArrow={false}
                      style={{
                        width: '100%',
                      }}
                      onChange={(newValue) =>
                        setSelectedCategoryId({
                          ...selectedCategoryId,
                          origin: newValue,
                        })
                      }
                      options={Object.values(
                        matchedCategories.originCategories
                      ).map((c) => ({
                        value: c.categoryId,
                        label: c.categoryName,
                      }))}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Col>
                  <Col
                    span={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowRightOutlined />
                  </Col>
                  <Col span={10}>
                    <Select
                      id="destination-category-select"
                      showSearch
                      notFoundContent={null}
                      defaultActiveFirstOption={false}
                      placeholder={SEARCH_PLACEHOLDER}
                      showArrow={false}
                      filterOption={false}
                      style={{
                        width: '100%',
                      }}
                      onSearch={(newValue) =>
                        debouncedHandleProductTypeSearch(newValue)
                      }
                      onChange={(newValue) => {
                        setSelectedCategoryId({
                          ...selectedCategoryId,
                          destination: JSON.parse(newValue),
                        });
                      }}
                      options={selectOptions()}
                    />
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'right',
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={handleGroupProductTypeChange}
                    >
                      {t('common.assign')}
                    </Button>
                  </Col>
                  <Collapse
                    bordered={false}
                    defaultActiveKey={null}
                    ghost
                    style={{
                      width: '100%',
                    }}
                  >
                    {matchedCategories?.originCategories &&
                      Object.entries(matchedCategories.originCategories)
                        .filter((x) => x[1].skus.length > 0)
                        .map(([index, category]) => (
                          <Collapse.Panel
                            header={category.categoryName}
                            key={index}
                          >
                            <Col span={10}>
                              <Search
                                placeholder={t(
                                  'catalogue.publishProductsModal.filterBySku'
                                )}
                                allowClear
                                enterButton={<SearchOutlined />}
                                size="small"
                                onSearch={(newValue) =>
                                  setSkusFilter({
                                    ...skusFilter,
                                    [category.categoryId]: newValue,
                                  })
                                }
                              />
                            </Col>

                            <List
                              size="small"
                              style={
                                category.skus.length > 4
                                  ? {
                                      marginTop: 12,
                                      maxHeight: 200,
                                      overflowY: 'auto',
                                    }
                                  : { marginTop: 12 }
                              }
                              bordered
                              dataSource={category.skus
                                .filter((s) =>
                                  skusFilter[category.categoryId]
                                    ? s
                                        ?.toLowerCase()
                                        .includes(
                                          skusFilter[
                                            category.categoryId
                                          ].toLowerCase()
                                        )
                                    : s
                                )
                                .map((s) => (
                                  <>
                                    <Col span={10}>{s}</Col>
                                    <Col
                                      span={1}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <ArrowRightOutlined />
                                    </Col>
                                    <Col span={13}>
                                      {activeSku === s ? (
                                        <Select
                                          showSearch
                                          notFoundContent={
                                            fetchingOptions ? (
                                              <Row justify="space-around">
                                                <Space>
                                                  <Spin size="small" />
                                                  {t(
                                                    'catalogue.publishProductsModal.searching'
                                                  )}
                                                </Space>
                                              </Row>
                                            ) : null
                                          }
                                          placeholder={SEARCH_PLACEHOLDER}
                                          defaultActiveFirstOption={false}
                                          showArrow={false}
                                          filterOption={false}
                                          style={{
                                            width: '100%',
                                          }}
                                          onSearch={(newValue) =>
                                            debouncedHandleProductTypeSearch(
                                              newValue
                                            )
                                          }
                                          onChange={(newValue) => {
                                            handleSkuProductTypeChange(
                                              JSON.parse(newValue),
                                              s
                                            );
                                          }}
                                          options={selectOptions()}
                                          onBlur={() => setActiveSku(null)}
                                          autoFocus
                                        />
                                      ) : (
                                        <Button
                                          className="width100"
                                          onClick={() => setActiveSku(s)}
                                          title={
                                            categories[
                                              categorization.destination[s]
                                            ] || '- - - - - - - -'
                                          }
                                        >
                                          <span
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              maxWidth: '100%',
                                              display: 'block',
                                            }}
                                          >
                                            {categories[
                                              categorization.destination[s]
                                            ] || '- - - - - - - -'}
                                          </span>
                                        </Button>
                                      )}
                                    </Col>
                                  </>
                                ))}
                              renderItem={(item) => (
                                <List.Item>{item}</List.Item>
                              )}
                            />
                          </Collapse.Panel>
                        ))}
                  </Collapse>
                </Row>
              </Timeline.Item>
              <Timeline.Item style={{ paddingBottom: 0 }}>
                <Row gutter={[16, 32]}>
                  <Col span={24}>
                    <Row className="buttonsContainer" justify="space-between">
                      <Col
                        span={11}
                        className="leftButtonsContainer step4FirstPost"
                      >
                        <Paragraph>
                          {t(
                            'catalogue.publishProductsModal.downloadTemplateCategory'
                          )}
                        </Paragraph>
                        <Button
                          className="btn-basic leftButton width100"
                          onClick={handleDownload}
                          disabled={!allCategoriesMatched}
                        >
                          {t('common.download')}
                        </Button>
                      </Col>
                      <Col
                        span={11}
                        className="rightButtonsContainer step5FirstPost"
                      >
                        <Paragraph>
                          {t(
                            'catalogue.publishProductsModal.uploadTemplateCategory'
                          )}
                        </Paragraph>
                        <Upload
                          maxCount={1}
                          name="file"
                          beforeUpload={beforeUpload}
                          className="uploadProductsFile"
                        >
                          <Button
                            className="btn-basic width100 rightButton"
                            disabled={!allCategoriesMatched}
                          >
                            {t('common.uploadFile')}
                          </Button>
                        </Upload>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className="btn-basic-green step6FirstPost"
                        disabled={!uploadFile}
                        onClick={handleUpload}
                        style={{ width: '20%' }}
                      >
                        {t('products.fields.uploadAction')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Timeline.Item>
            </>
          ) : (
            <div style={{ height: 300 }} />
          )}
        </Timeline>
      </Spin>
    </Drawer>
  );
}

ExportProductsModal.propTypes = {
  marketplaceToExport: PropTypes.string.isRequired,
  setMarketplaceToExport: PropTypes.func.isRequired,
  selectedListings: PropTypes.arrayOf(PropTypes.string).isRequired,
  originMarketplace: PropTypes.string.isRequired,
  all: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default ExportProductsModal;
