import api from './api';

const prefix = 'documents';

export default {
  findById(id) {
    return api.get(`${prefix}/${id}`);
  },
  insert(object) {
    return api.post(`${prefix}`, object);
  },
  update(object) {
    return api.put(`${prefix}`, object);
  },
  pin(idToPin) {
    return api.put(`${prefix}/${idToPin}/pin`);
  },
  delete(id) {
    return api.delete(`${prefix}/${id}`);
  },
  get(restockOrderId, shipmentId, hasFood) {
    return api.get(
      `${prefix}/certificateOrigin/${restockOrderId}/${shipmentId}`,
      { params: { hasFood } }
    );
  },
  createPriorNotice(restockId, values) {
    const body = {
      restockId,
      productData: values,
    };
    return api.post(`${prefix}/prior-notice`, body);
  },
  getRegulationsCredentials(params = {}) {
    return api.get(`${prefix}/credentials`, params);
  },
  syncRegulationsCredentials: (body) => api.post(`${prefix}/sync`, body),
};
