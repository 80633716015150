import PropTypes from 'prop-types';
import { Card, Tag, Row, Col, Radio, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

function ShipmentTypeAndPriceCard({
  tag,
  courier,
  title,
  date,
  totalCost,
  discountCost,
  cardKey,
  checked,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <Card
      className="shipment-options-card"
      style={
        checked
          ? {
              border: '1px solid rgba(0, 229, 166, 1)',
              backgroundColor: 'rgba(0, 229, 166, 0.05)',
              borderRadius: 4,
            }
          : { borderRadius: 4 }
      }
    >
      <Row align="middle" wrap={false}>
        <Col flex="20px" style={{ marginRight: 10 }}>
          <Radio value={cardKey} checked={checked} onChange={onChange} />
        </Col>
        <Col flex="auto">
          <Row wrap>
            <Col span={12} style={{ display: 'flex' }}>
              <Row align="middle" justify="space-between">
                {tag && (
                  <Col span={24}>
                    <Tag
                      color="rgba(0, 229, 166, 0.5)"
                      className="shipment-options-card-tag"
                    >
                      {tag}
                    </Tag>
                  </Col>
                )}
                <Col span={24}>
                  <Typography.Title className="shipment-options-card-title">
                    {title}
                  </Typography.Title>
                </Col>
                {date && (
                  <Col span={24}>
                    <Typography.Title className="shipment-options-card-date">
                      {date}
                    </Typography.Title>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={12}>
              <Row
                align="space between"
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Tag
                  color="rgba(250, 250, 250, 1)"
                  className="shipment-options-card-priceTag"
                >
                  {discountCost && (
                    <Col
                      span={24}
                      className="shipment-options-card-discountCost"
                    >
                      Costo total FedEx: {discountCost}
                    </Col>
                  )}
                  <Col span={24} className="shipment-options-card-cost">
                    {discountCost
                      ? t('shipmentOptions.totalLAPCost')
                      : t('shipmentOptions.totalServiceCost', { courier })}
                    : {totalCost}
                  </Col>
                </Tag>
              </Row>{' '}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

ShipmentTypeAndPriceCard.propTypes = {
  tag: PropTypes.string,
  courier: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  totalCost: PropTypes.string.isRequired,
  discountCost: PropTypes.string,
  cardKey: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

ShipmentTypeAndPriceCard.defaultProps = {
  tag: null,
  date: null,
  discountCost: null,
};

export default ShipmentTypeAndPriceCard;
