import { Link } from 'react-router-dom';
import {
  Avatar,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined, PictureOutlined } from '@ant-design/icons';
import phones from '../../../../../../utils/json/phones.json';
import getLinkToStore from '../../../../../home/components/linkToStore';
import './FedexFbmProductTable.css';
import { convertWeightUnit } from '../../../../../../utils/functions';

function FedexFbmProductTable({ form, order, products, shipmentType, unit }) {
  const { t } = useTranslation();

  const rowClassName = (record) => {
    if (record.type === 'product') {
      return 'product';
    }
    if (record.type === 'listing') {
      return 'listing';
    }
    return null;
  };

  useEffect(() => {
    products?.forEach((product) => {
      form.setFieldsValue({
        [`fob-${product.defaultCode}`]: product.productTemplate.unitFOB,
        [`harmonizedCode-${product.defaultCode}`]:
          product.productTemplate.harmonizedCode,
        [`countryOfManufacture-${product.defaultCode}`]:
          product.productTemplate.countryOfManufacture,
        [`weight-${product.defaultCode}`]: convertWeightUnit(
          product.weightUnity || product.productTemplate.weightUnity,
          unit,
          product.weight || product.productTemplate.weight
        ),
        [`weightUnity`]: unit === 'CM' ? 'KG' : 'CM',
      });
    });
  }, [products]);

  useEffect(() => {
    const formValues = form.getFieldsValue();
    const newValues = { [`weightUnity`]: unit === 'CM' ? 'KG' : 'LB' };
    products?.forEach((product) => {
      if (
        product &&
        formValues[`weight-${product.defaultCode}`] !== undefined
      ) {
        const updatedWeight = convertWeightUnit(
          unit === 'CM' ? 'LB' : 'KG',
          unit,
          parseFloat(formValues[`weight-${product.defaultCode}`])
        );
        newValues[`weight-${product.defaultCode}`] = updatedWeight;
      }
    });
    form.setFieldsValue({ ...newValues });
  }, [unit]);

  const renderProduct = (value, record) => {
    const imgUrl =
      record?.mainImages?.length > 0 ? record?.mainImages[0]?.url : null;
    return record?.orderItems.map((item) => (
      <Row align="middle" justify="start">
        <Col
          span={24}
          style={{
            marginBottom: -10,
            textOverflow: 'ellipsis',
            overflow: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: 20,
            }}
          >
            <Space size="middle">
              {imgUrl ? (
                <Avatar
                  shape="square"
                  size="large"
                  src={<Image src={`${imgUrl}`} />}
                />
              ) : (
                <Avatar
                  shape="square"
                  size="large"
                  icon={<PictureOutlined />}
                />
              )}
              <Space direction="vertical" style={{ textAlign: 'left' }}>
                <p
                  style={{
                    marginBottom: -10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getLinkToStore[record?.marketplace](item) || 'Sin datos'}
                </p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`SKU: ${
                  item?.sku || 'Sin datos'
                } / Cantidad: ${item?.quantity || 'Sin datos'}`}</p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`ID: ${
                  item?.itemId || 'Sin datos'
                }`}</p>
              </Space>
            </Space>
          </div>
        </Col>
      </Row>
    ));
  };

  const renderUnitPrice = (value, record) => {
    return record?.orderItems.map((item) => (
      <Row align="middle" justify="center">
        $ {item.itemPrice?.amount} {item.itemPrice?.currency}
      </Row>
    ));
  };

  const renderCountryOfManufacture = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        wrapperCol={{ span: 24 }}
        className="input-form-margin-bottom"
        name={`countryOfManufacture-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {phones.map((phoneRow) => (
            <Select.Option key={phoneRow.name} value={phoneRow.code}>
              {phoneRow.flag} {phoneRow.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ));
  };

  const renderHarmonizedCode = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        wrapperCol={{ span: 24 }}
        className="input-form-margin-bottom"
        name={`harmonizedCode-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input
          style={{ textAlign: 'center' }}
          name={`harmonizedCode-${item.sku}`}
          min={0}
        />
      </Form.Item>
    ));
  };

  const renderFob = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        label=""
        wrapperCol={{ span: 24 }}
        className="input-form-margin-bottom"
        name={`fob-${item.sku}`}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <Input addonBefore="USD" name={`fob-${item.sku}`} min={0} />
      </Form.Item>
    ));
  };

  const renderWeight = (value, record) => {
    return record?.orderItems.map((item) => (
      <Form.Item
        name={`weight-${item.sku}`}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: 'Requerido',
          },
        ]}
      >
        <InputNumber
          min="0.01"
          placeholder="0.01"
          addonBefore={
            <Form.Item
              name="weightUnity"
              initialValue={unit === 'CM' ? 'KG' : 'LB'}
              noStyle
            >
              <Select style={{ width: 55 }}>
                <Select.Option value="KG">kg</Select.Option>
                <Select.Option value="LB">lb</Select.Option>
              </Select>
            </Form.Item>
          }
          step="0.01"
          stringMode
          controls={false}
        />
      </Form.Item>
    ));
  };

  const columns = [
    {
      title: t('common.description'),
      dataIndex: 'name',
      key: 'product',
      render: renderProduct,
      width: `27%`,
      onFilter: (value, record) =>
        record.name.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
    },
    {
      title: t('orders.shipment.products.unitPrice'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: renderUnitPrice,
      width: `12%`,
      align: 'center',
    },
    {
      title: t('calculator.unit.weight'),
      dataIndex: 'weight',
      key: 'weight',
      render: renderWeight,
      width: `15%`,
      align: 'center',
    },
    ...(shipmentType === 'INTERNATIONAL'
      ? [
          {
            title: (
              <Tooltip
                title={t(
                  'orders.shipment.products.countryOfManufactureTooltip'
                )}
              >
                <Space>
                  {t('orders.shipment.products.countryOfManufacture')}
                  <InfoCircleOutlined />
                </Space>
              </Tooltip>
            ),
            dataIndex: 'countryOfManufacture',
            key: 'countryOfManufacture',
            render: renderCountryOfManufacture,
            width: `15%`,
            align: 'center',
          },
          {
            title: (
              <Tooltip
                title={
                  <>
                    {t('orders.shipment.products.harmonizedCodeTooltip')}{' '}
                    <Link
                      to={{
                        pathname:
                          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955',
                      }}
                      target="_blank"
                    >
                      {t('common.wantKnowMore')}
                    </Link>
                  </>
                }
              >
                <Space>
                  {t('orders.shipment.products.harmonizedCode')}
                  <InfoCircleOutlined />
                </Space>
              </Tooltip>
            ),
            key: 'harmonizedCode',
            dataIndex: 'harmonizedCode',
            render: renderHarmonizedCode,
            width: `15%`,
            align: 'center',
          },
          {
            title: (
              <Tooltip
                title={
                  <>
                    {t('orders.shipment.products.declaredFobTooltip')}{' '}
                    <Link
                      to={{
                        pathname:
                          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
                      }}
                      target="_blank"
                    >
                      {t('common.wantKnowMore')}
                    </Link>
                  </>
                }
              >
                <Space>
                  {t('orders.shipment.products.declaredFob')}
                  <InfoCircleOutlined />
                </Space>
              </Tooltip>
            ),
            key: 'fob',
            dataIndex: 'fob',
            render: renderFob,
            width: `15%`,
            align: 'center',
          },
        ]
      : []),
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {products && (
        <Table
          columns={columns}
          dataSource={[order]}
          rowClassName={rowClassName}
          pagination={false}
          sticky
        />
      )}
    </Space>
  );
}

export default FedexFbmProductTable;

FedexFbmProductTable.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  products: PropTypes.arrayOf(Object),
  form: PropTypes.instanceOf(Form).isRequired,
  shipmentType: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

FedexFbmProductTable.defaultProps = {
  products: [],
};
