import { Link } from 'react-router-dom';
import {
  DeleteTwoTone,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import phones from '../../../utils/json/phones.json';

function ProductsTable({
  products,
  setProducts,
  lengthUnit,
  currentStep,
  initialProductsNumber,
  form,
  shipmentType,
  setDefaultImporter,
}) {
  const { t } = useTranslation();
  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));
  const { Text } = Typography;

  const [importerData, setImporterData] = useState({
    isFood: {},
    totalFOB: {},
  });

  useEffect(() => {
    const newData = { isFood: {}, totalFOB: {} };
    products.forEach((_, index) => {
      newData.isFood[index] = form.getFieldValue(['products', index, 'isFood']);
      newData.totalFOB[index] =
        form.getFieldValue(['products', index, 'quantity']) *
        form.getFieldValue(['products', index, 'unitPrice']);
    });
    setImporterData(newData);
  }, [products]);

  useEffect(() => {
    if (Object.values(importerData.isFood).find((x) => x === true)) {
      return setDefaultImporter(true);
    }
    const totalFOB = Object.values(importerData.totalFOB).reduce(
      (prev, curr) => prev + (curr || 0),
      0
    );
    if (totalFOB >= 2500) {
      return setDefaultImporter(true);
    }
    return setDefaultImporter(false);
  }, [importerData]);

  const deleteProduct = (key) => {
    const newData = products.filter((item) => item.key !== key);
    const newProducts = form.getFieldsValue(['products']).products;
    delete newProducts[key];
    form.setFieldValue(['products'], newProducts);
    setProducts(newData);
  };

  const columns = [
    {
      title: t('orders.shipment.products.description'),
      dataIndex: 'description',
      key: 'description',
      width: '24%',
      render: (_value, _record, index) => {
        return (
          <Form.Item
            name={['products', index, 'description']}
            rules={[
              { required: true, message: 'Requerido' },
              {
                min: 6,
                message: t('orders.shipment.products.descriptionValidation'),
              },
            ]}
          >
            <Input
              disabled={currentStep > 0}
              placeholder={t('orders.shipment.products.productDescription')}
            />
          </Form.Item>
        );
      },
    },
    ...(shipmentType === 'INTERNATIONAL'
      ? [
          {
            title: t('orders.shipment.products.isFood'),
            dataIndex: 'isFood',
            key: 'isFood',
            width: '11%',
            align: 'center',
            render: (_value, _record, index) => {
              return (
                <Form.Item
                  name={['products', index, 'isFood']}
                  valuePropName="checked"
                  onChange={(e) => {
                    setImporterData({
                      isFood: {
                        ...importerData.isFood,
                        [index]: e.target.checked,
                      },
                      totalFOB: { ...importerData.totalFOB },
                    });
                  }}
                >
                  <Checkbox />
                </Form.Item>
              );
            },
          },
          {
            title: (
              <Row wrap={false}>
                <Col flex="auto">
                  {t('orders.shipment.products.countryOfManufacture')}
                </Col>
                <Col flex="none">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Tooltip
                      title={t(
                        'orders.shipment.products.countryOfManufactureTooltip'
                      )}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            ),
            dataIndex: 'countryOfManufacture',
            key: 'countryOfManufacture',
            width: '13%',
            align: 'center',
            render: (_value, _record, index) => {
              return (
                <Form.Item
                  name={['products', index, 'countryOfManufacture']}
                  rules={[{ required: true, message: 'Requerido' }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder={t('orders.shipment.products.select')}
                    disabled={currentStep > 0}
                  >
                    {phones.map((row) => (
                      <Select.Option key={row.name} value={row.code}>
                        {row.flag} {row.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            },
          },
          {
            title: (
              <Row wrap={false}>
                <Col flex="auto">{t('orders.shipment.products.unitFob')}</Col>
                <Col flex="none">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Tooltip
                      title={
                        <>
                          {t('orders.shipment.products.unitFobTooltip')}{' '}
                          <Link
                            to={{
                              pathname:
                                'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
                            }}
                            target="_blank"
                          >
                            {t('common.wantKnowMore')}
                          </Link>
                        </>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            ),
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            width: '14%',
            align: 'center',
            render: (_value, _record, index) => {
              return (
                <Form.Item
                  name={['products', index, 'unitPrice']}
                  rules={[{ required: true, message: 'Requerido' }]}
                >
                  <InputNumber
                    addonBefore={<Typography.Text>USD</Typography.Text>}
                    placeholder="10"
                    formatter={formatterNumber}
                    parser={parserNumber}
                    precision={2}
                    disabled={currentStep > 0}
                    controls={false}
                    min={0}
                    onChange={(value) => {
                      setImporterData({
                        isFood: { ...importerData.isFood },
                        totalFOB: {
                          ...importerData.totalFOB,
                          [index]:
                            value *
                            form.getFieldValue(['products', index, 'quantity']),
                        },
                      });
                    }}
                  />
                </Form.Item>
              );
            },
          },
          {
            title: (
              <Row wrap={false}>
                <Col flex="auto">
                  {t('orders.shipment.products.harmonizedCode')}
                </Col>
                <Col flex="none">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Tooltip
                      title={
                        <>
                          {t('orders.shipment.products.harmonizedCodeTooltip')}{' '}
                          <Link
                            to={{
                              pathname:
                                'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955',
                            }}
                            target="_blank"
                          >
                            {t('common.wantKnowMore')}
                          </Link>
                        </>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            ),
            dataIndex: 'harmonizedCode',
            key: 'harmonizedCode',
            width: '13%',
            align: 'center',
            render: (_value, _record, index) => {
              return (
                <Form.Item
                  name={['products', index, 'harmonizedCode']}
                  rules={[
                    { required: true, message: 'Requerido' },
                    () => ({
                      validator(_, value) {
                        return value?.replace('.', '')?.length >= 6
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                t(
                                  'orders.shipment.products.harmonizedCodeValidation'
                                )
                              )
                            );
                      },
                    }),
                  ]}
                >
                  <Input
                    disabled={currentStep > 0}
                    placeholder={t('orders.shipment.products.code')}
                  />
                </Form.Item>
              );
            },
          },
        ]
      : []),
    {
      title: t('orders.shipment.products.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: '10%',
      align: 'center',
      render: (_value, _record, index) => {
        return (
          <Form.Item
            name={['products', index, 'quantity']}
            rules={[{ required: true, message: 'Requerido' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              disabled={currentStep > 0}
              placeholder={100}
              precision={0}
              min={0}
              onChange={(value) => {
                setImporterData({
                  isFood: { ...importerData.isFood },
                  totalFOB: {
                    ...importerData.totalFOB,
                    [index]:
                      value *
                      form.getFieldValue(['products', index, 'unitPrice']),
                  },
                });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: t('orders.shipment.products.unitWeight'),
      dataIndex: 'weight',
      key: 'weight',
      width: '12%',
      align: 'center',
      render: (_value, _record, index) => {
        return (
          <Form.Item
            name={['products', index, 'weight']}
            rules={[{ required: true, message: 'Requerido' }]}
          >
            <InputNumber
              placeholder="10"
              addonAfter={<Text>{lengthUnit === 'cm' ? 'kg' : 'lb'}</Text>}
              formatter={formatterNumber}
              parser={parserNumber}
              precision={2}
              disabled={currentStep > 0}
              controls={false}
              min={0}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '',
      dataIndex: 'erase',
      align: 'center',
      render: (_, record) => (
        <Popconfirm
          title={t('orders.shipment.products.actionsButton')}
          onConfirm={() => deleteProduct(record.key)}
          disabled={currentStep > 0}
        >
          <DeleteTwoTone twoToneColor="#646464" />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const previousProducts = [];
    for (
      let productNumber = products.length;
      productNumber < initialProductsNumber;
      productNumber += 1
    ) {
      previousProducts.push({ key: productNumber });
    }
    setProducts([...products, ...previousProducts]);
  }, [initialProductsNumber]);

  const handleAddProduct = () => {
    setProducts([...products, { key: products.length }]);
  };
  return (
    <Col span={24}>
      <Table pagination={false} dataSource={products} columns={columns} />
      <Row>
        <Col span={4}>
          <Button
            icon={<PlusOutlined />}
            onClick={handleAddProduct}
            className="addRowTableButton"
            disabled={currentStep > 0}
          >
            Agregar producto
          </Button>
        </Col>
      </Row>
    </Col>
  );
}

ProductsTable.propTypes = {
  products: PropTypes.arrayOf(Object).isRequired,
  setProducts: PropTypes.func.isRequired,
  lengthUnit: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  initialProductsNumber: PropTypes.number.isRequired,
  form: PropTypes.instanceOf(Form).isRequired,
  shipmentType: PropTypes.string.isRequired,
  setDefaultImporter: PropTypes.func.isRequired,
};

export default ProductsTable;
